<template>
    <div>
        <!-- survey title, survey description -->
        <v-row class="mt-5">
            <v-col cols="12">
                <h1 class="display-1 text-center font-weight-bold">{{ setLanguage(title, selectedLang) }}</h1>
                <div class="text-center font-weight-medium mt-5" v-html="setLanguage(description, selectedLang)"></div>
            </v-col>
        </v-row>

        <!-- survey completion message (show when assignation state is Closed) -->
        <v-row v-if="!!activeAssignation && activeAssignation.state === 'Closed'" class="mt-5">
            <v-col cols="12">
                <h2 class="text-center success--text">{{ getCompletionMessage() }}</h2>
            </v-col>
        </v-row>

        <!-- assignation status, assignation id, assignator name, creation date, update date -->
        <v-row class="mt-5 print-small-y-margin">
            <v-col cols="12">
                <p class="text-center print-small-y-margin">
                    <strong>{{ selectedLang === 'fr' ? 'État assignation: ' : selectedLang === 'en' ? 'Assignation state: ' : 'État assignation: ' }}</strong>
                    <v-chip
                        v-if="!!activeAssignation && !!activeAssignation.stateLabels"
                        :color="activeAssignation.stateLabels.color"
                    >
                        {{ activeAssignation.stateLabels[selectedLang === 'fr' ? 'label_fr' : 'name'] }}
                    </v-chip>
                </p>
                <p v-if="activeAssignation.state === 'Closed' " class="text-center print-small-y-margin">
                    <strong>Score : </strong>
                    {{ getSurveyScore() }}
                </p>
                <p class="text-center print-small-y-margin">
                    <strong>{{ selectedLang === 'fr' ? 'Id assignation: ' : selectedLang === 'en' ? 'Assignation Id: ' : 'Id assignation: ' }}</strong>
                    {{ !!activeAssignation ? activeAssignation.id : '' }}
                </p>
                <p class="text-center print-small-y-margin">
                    <strong>{{ selectedLang === 'fr' ? 'Nom assignateur: ' : selectedLang === 'en' ? 'Assignator name: ' : 'Nom assignateur: ' }}</strong>
                    {{ !!activeAssignation && !!activeAssignation.user ? activeAssignation.user.fullName : '' }}
                </p>
                <p class="text-center print-small-y-margin">
                    <strong>{{ selectedLang === 'fr' ? 'Nom assigné: ' : selectedLang === 'en' ? 'Assigned user name: ' : 'Nom assigné: ' }}</strong>
                    {{ !!activeAssignation && !!activeAssignation.assignedUser ? activeAssignation.assignedUser.fullName : !!activeAssignation.assignedContact ? activeAssignation.assignedContact.fullName : activeAssignation.email }}
                </p>
                <p class="text-center print-small-y-margin">
                    <strong>{{ selectedLang === 'fr' ? 'Date création: ' : selectedLang === 'en' ? 'Creation date: ' : 'Date création: ' }}</strong>
                    {{ !!activeAssignation ? activeAssignation.createdAt : '' }}
                </p>
                <p class="text-center print-small-y-margin">
                    <strong>{{ selectedLang === 'fr' ? 'Dernière mise à jour: ' : selectedLang === 'en' ? 'Last update: ' : 'Dernière mise à jour: ' }}</strong>
                    {{ !!activeAssignation ? activeAssignation.updatedAt : '' }}
                </p>
            </v-col>
        </v-row>

        <!-- survey stage, survey stage unit, survey client, survey project number -->
        <v-row justify="center" class="mt-5 print-small-y-margin">
            <!--<v-col v-if="stageUnitId && activeAssignation.stageUnit" cols="9">
                <p class="align-center-and-text-bold primary--text">{{ selectedLang === 'fr' ? 'Modèle Générique' : 'Generic Model' }}:&emsp; {{ genericModel }}</p>
            </v-col>-->
            <v-col cols="9">
                <v-select
                    v-if="!!stageUnitId"
                    :value="stageUnitId"
                    :items="!!stageUnits ? stageUnits : []"
                    item-text="unit"
                    item-value="id"
                    :label="selectedLang === 'fr' ? 'Unité de scène' : selectedLang === 'en' ? 'Stage Unit' : 'Unité de scène'"
                    class="mt-3 print-none"
                    @change="emitStageUnitIdChange"
                    :readonly="readOnlyUnit === true"
                />

                <p class="text-center mt-3" v-if="!!activeAssignation && !!activeAssignation.stageModel">
                    <strong>{{ selectedLang === 'fr' ? 'Modèle de scène:' : selectedLang === 'en' ? 'Stage model:' : 'Modèle de scène:' }}</strong> {{ activeAssignation.stageModel.model }}
                </p>

                <p class="text-center mt-3 print-stage-unit" v-if="!!activeAssignation && !!activeAssignation.stageUnit">
                    <strong>{{ selectedLang === 'fr' ? 'Unité de scène:' : selectedLang === 'en' ? 'Stage unit:' : 'Unité de scène:' }}</strong> {{ getStageUnitById() }}
                </p>

                <p class="text-center mt-3" v-if="!!activeAssignation && !!activeAssignation.clientSupplier">
                    <strong>{{ selectedLang === 'fr' ? 'Nom client:' : selectedLang === 'en' ? 'Client name:' : 'Nom client:' }}</strong> {{ activeAssignation.clientSupplier.name }}
                </p>

                <p class="text-center" v-if="!!activeAssignation && !!activeAssignation.projectNumber">
                    <strong>{{ selectedLang === 'fr' ? 'Numéro projet:' : selectedLang === 'en' ? 'Project number:' : 'Numéro projet:' }}</strong> {{ activeAssignation.projectNumber }}
                </p>
            </v-col>
        </v-row>

        <!-- survey start button -->
        <v-row class="my-0 py-0 text-center print-none" v-if="$router.currentRoute.name !== 'print-assignation'">
            <v-col cols="12">
                <v-btn @click="emitStartClick" color="primary">{{ selectedLang === 'fr' ? 'Commencer' : 'Start' }}<v-icon class="ml-1">arrow_forward</v-icon></v-btn>
            </v-col>
        </v-row>
        <v-row class="my-0 py-0 text-center print-none" v-else>
            <v-col cols="12">
                <v-file-input
                    label="Upload PDF"
                    accept=".pdf"
                    v-model="pdfFile"
                    style="flex:1; margin: 0 auto; width:50%;"
                ></v-file-input>
                <v-btn @click="transferPdf" color="blue" style="flex:1; width:50%; margin-bottom: 15px;">
                    {{ selectedLang === 'fr' ? 'Transférer vers dossier cible' : 'Transfer to target folder' }}
                    <v-icon class="ml-1">mdi-file-send</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import shared from '../../shared';

    export default {
        name: "SurveyInfo",
        props: ['activeAssignation', 'activeSurvey', 'selectedLang', 'stageUnits', 'stageUnitId', 'readOnlyUnit'],
        mounted() {
            this.setLanguage = shared.setLanguage;
        },
        data: function() {
            return {
                //shared functions from shared.js
                setLanguage: function() {},
                pdfFile: null
            }
        },
        computed: {
            title() {
                return !!this.activeAssignation ? this.activeAssignation.surveyObject.title : this.activeSurvey.title;
            },
            description() {
                return !!this.activeAssignation ? this.activeAssignation.surveyObject.description : this.activeSurvey.description;
            }
        },
        methods: {
            ...mapActions(['fetchAssignationPdfToServerFolder']),

            //Gets message to show to user when survey is done (when assignation state is Closed)
            getCompletionMessage() {
                let message = '';

                if(this.selectedLang === 'en') {
                    message = "You have successfully completed the survey. It is now impossible to modify any of the answers.";
                }
                else {
                    message = "Vous avez complété le questionnaire avec succès. Il est maintenant impossible de modifier les réponses de celui-ci.";
                }

                return message;
            },
            getStageUnitById() {
                const stageUnit = this.stageUnits.find(stageUnit => stageUnit.id === this.stageUnitId);
                return stageUnit ? stageUnit.unit : '';
            },

            emitStageUnitIdChange(stageUnitId) {
                this.$emit('stageUnitIdChange', stageUnitId);
            },
            emitStartClick() {
                this.$emit('startClick');
            },

            //Survey Score
            getSurveyScore(){
                let total = 0;
                let countNotOk = 0;

                //Question whit observation...
                this.activeAssignation.answers.forEach(answer => {
                    //...Observation not okay!
                    if(answer.value){
                        if(answer.optionsValue?.optionComments){
                            countNotOk++;
                        }
                        else if(answer.optionsValue?.optionYesNo === 1){
                            countNotOk++;
                        }
                    }
                });

                this.activeAssignation.surveyObject.sections.forEach(section => {
                    section.questions.forEach(() => {
                        total++;
                    });
                });

                let results = (countNotOk * 100) / total;
                results = 100 - results;
                results = Math.ceil(results);
                return results + "%";
            },
            getQuestionAnswer(questionId) {
                return this.activeAssignation.answers.find((answer) => {
                    return answer.questionId === questionId;
                });
            },

            //Tranfère vers dossier cible
            async transferPdf() {
                if(this.pdfFile == null) {
                    alert("Vous devez choisir un PDF à transférer vers un dossier cible!");
                }
                else {
                    let stageUnit = this.getStageUnitById();
                    const model = this.activeAssignation.stageUnit.stage.model;
                    const folderName = stageUnit + "-" + model;
                    const oldFileName = this.pdfFile.name;
                    if(stageUnit[0] === "0") {
                        stageUnit = stageUnit.slice(1);
                    }
                    const newFileName = stageUnit + " - Inspection Qualité"
                    let formData = new FormData();

                    alert("Le nom du fichier (" + oldFileName + "), sera remplacé par (" + newFileName + ") et enregistrer dans le dossier => /scenes/" + folderName + "/1. Tel que livré/2. Liste de contrôle de la qualité/" + newFileName + ".pdf");

                    formData.append("file", this.pdfFile);
                    formData.append("newFileName", newFileName);
                    formData.append("folderName", folderName);
                    await this.fetchAssignationPdfToServerFolder(formData);
                }
            },
        },
    }
</script>

<style scoped>
    .align-center-and-text-bold {
        text-align: center;
        font-weight: bold;
    }

    /* hides stage unit print text */
    .print-stage-unit {
        display: none;
    }

    /* print stylesheet */
    @media print {
        .print-none {
            display: none;
        }

        .print-stage-unit {
            display: block;
        }

        .print-small-y-margin {
            margin-top: 5px;
            margin-bottom: 5px;
        }
    }
</style>
