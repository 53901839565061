<template>
    <!-- margin here is to match the margin of the app bar when outputting so we can show the index menu as well -->
    <div id="content" ref="contentToPDF" :style="!!assignationIdToOutput ? 'margin-top: 65px' : ''">
        <!-- menu button and slider -->
        <!-- if we are previewing the survey, we change the screen format (fixed props) -->
        <v-app-bar :fixed="!assignationToPreview && !surveyIdToPreview" class="print-none" :style="!!assignationIdToOutput ? 'margin-top: 65px' : ''">
            <!-- button open drawer menu -->
            <v-app-bar-nav-icon @click="showDrawer" class="primary white--text" title="Ouvrir menu"/>

            <v-tabs
                v-if="!!toggleTabs"
                v-model="tab"
                active-class="grey lighten-4 black--text scale"
                center-active
                centered
                icons-and-text
                hide-slider
                show-arrows
            >
                <v-tab>
                    <v-icon>info</v-icon>
                    Info
                </v-tab>
                <v-tab v-for="(section, sectionIndex) in sections" :key="section.id">
                    <v-icon v-if="section.optional && getSectionSkip(section.id)" color="green">check_circle</v-icon>
                    <v-icon v-else-if="!!isSubmit && !getSectionCompleted(section)" color="red">cancel</v-icon>
                    <v-icon v-else-if="!getSectionCompleted(section)">fiber_manual_record</v-icon>
                    <v-icon v-else color="green">check_circle</v-icon>
                    {{ getSectionCompletion(section) }} / {{ section.questions.length }}
                </v-tab>
            </v-tabs>
        </v-app-bar>

        <!-- drawer menu -->
        <!-- if we are previewing the survey, we change the screen format (app and absolute props) -->
        <v-navigation-drawer
            v-model="drawer"
            :app="!assignationToPreview && !surveyIdToPreview"
            :absolute="!!assignationToPreview || !!surveyIdToPreview"
            width="700px"
            style="z-index: 10"
        >
            <!-- button close drawer menu -->
            <p class="text-right mt-2 mr-2">
                <v-btn fab depressed class="primary" @click="showDrawer">
                    <v-icon large title="Fermer menu">clear</v-icon>
                </v-btn>
            </p>

            <!-- button change language -->
            <p
                class="ml-4 mt-4"
                @click="dialogSelectLang = true"
            >
                <v-btn class="purple white--text">
                    <v-icon>emoji_flags</v-icon>&ensp; {{selectedLang}} - {{ selectedLang === 'en' ? 'Change language' : 'Changer langue' }}
                </v-btn>
            </p>

            <!-- button submit survey -->
            <p
                v-if="!readonly"
                class="ml-4 mt-4"
                @click="submitSurvey"
            >
                <v-btn class="success">
                    <v-icon>cloud_upload</v-icon>&ensp; {{ selectedLang === 'en' ? 'Submit' : 'Soumettre' }}
                </v-btn>
            </p>

            <v-list>
                <v-list-item-group v-model="tab">
                    <v-list-item @click="showDrawer">
                        <v-list-item-icon>
                            <v-icon>info</v-icon>
                        </v-list-item-icon>

                        <v-list-item-title>Info</v-list-item-title>
                    </v-list-item>

                    <!-- the value prop will expand the active section -->
                    <v-list-group v-for="(section, sectionIndex) in sections" :key="section.id" :value="tab === sectionIndex + 1">
                        <template v-slot:activator>
                            <v-list-item-icon>
                                <v-icon v-if="section.optional" color="green">check_circle</v-icon>
                                <v-icon v-else-if="!!isSubmit && !getSectionCompleted(section)" color="red">cancel</v-icon>
                                <v-icon v-else-if="!getSectionCompleted(section)">fiber_manual_record</v-icon>
                                <v-icon v-else color="green">check_circle</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>{{ getSectionCompletion(section) }} / {{ section.questions.length }} {{ setLanguage(section.title, selectedLang) }}</v-list-item-title>
                        </template>

                        <!-- in a v-list-item-group so we can link all the questions in the survey to the questions in drawer menu individually (bind together with v-model="item" ) -->
                        <v-list-item-group v-model="item">
                            <a
                                class="black--text anchor"
                                v-for="(question, questionIndex) in section.questions"
                                :href="`#${!!question && !!question.id ? question.id.toString() : ''}`"
                                @click="showDrawer"
                            >
                                <v-list-item >
                                    <p>{{(questionIndex + 1)}} - &ensp;</p>

                                    <span
                                        v-if="!!question && !!question.question"
                                        v-html="setLanguage(question.question.title, selectedLang)"
                                    />
                                </v-list-item>
                            </a>
                        </v-list-item-group>
                    </v-list-group>

                    <p v-if="!!assignationIdToOutput" class="text-center mt-10">
                        <v-btn fab class="blue-grey" @click="toggle">
                            <v-icon title="Format page">format_align_justify</v-icon>
                        </v-btn>

                        <v-btn fab dark class="ml-4" @click="backToIndexAssignation">
                            <v-icon title="Retour index assignation">arrow_back</v-icon>
                        </v-btn>

                        <v-btn fab dark class="teal ml-4" @click="printAssignation">
                            <v-icon title="Imprimer">local_printshop</v-icon>
                        </v-btn>
                    </p>
                </v-list-item-group>
            </v-list>



            <!-- button load answers -->
            <p
                v-if="!readonly"
                class="ml-4 mt-4"
                @click="dialogImportFile = true"
            >
                <v-btn color="orange">
                    <v-icon title="Import">mdi-file-import</v-icon> {{ selectedLang === 'en' ? 'Load save file' : 'Charger fichier sauvegarde' }}
                </v-btn>
            </p>
        </v-navigation-drawer>

        <v-container class="mt-5 overflow-visible">
            <v-form ref="form">
                <!-- speed dial button that makes appear save, submit and language button -->
                <v-btn v-if="!readonly && !surveyIdToPreview" fab dark :color="dirty ? 'red' : 'blue'" @click="saveAnswers" :bottom="true" :right="true" :fixed="true">
                    <v-icon title="Sauvegarder">save</v-icon>
                </v-btn>
                <v-btn v-if="(!readonly && !surveyIdToPreview && !onLine) || (this.surveyType.autosaveImages)" fab dark color="orange" @click="saveJSON" :bottom="true" :right="false" :fixed="true">
                    <v-icon title="Sauvegarder fichier">mdi-content-save-all</v-icon>
                </v-btn>

                <!-- Dialog import file -->
                <v-dialog v-model="dialogImportFile" persistent>
                    <v-card>
                        <v-card-title>Import</v-card-title>
                        <v-card-text>
                            <v-file-input
                                @change="loadJSON"
                                accept="application/json"
                            ></v-file-input>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <v-btn @click="applyJSON">Confirm Import</v-btn>
                            <v-btn @click="dialogImportFile = false">Fermer</v-btn>
                        </v-card-actions>
                    </v-card>

                </v-dialog>

                <!-- dialog select language -->
                <v-dialog width="60%" v-model="dialogSelectLang">
                    <v-card>
                        <v-card-title>Langue</v-card-title>
                        <v-card-text>
                            <v-select
                                v-model="selectedLang"
                                :items="langOptions"
                                item-text="text"
                                item-value="value"
                                label="Langue"
                                class="mt-3"
                            />
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer />
                            <v-btn @click="dialogSelectLang = false">Fermer</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!-- dialog signature -->
                <v-dialog width="60%" v-model="dialogSignature">
                    <v-card>
                        <v-card-title>Signature</v-card-title>

                        <v-card-text>
                            <vueSignature class="signature mb-2" ref="signature"/>
                        </v-card-text>

                        <v-card-actions class="overflow-hidden">
                            <v-row justify="center">
                                <v-btn @click="$refs.signature.clear()">Effacer Tout</v-btn>
                                <v-btn class="ml-3" @click="$refs.signature.undo()">Effacer</v-btn>
                                <v-btn class="ml-3 error" @click="closeDialogSignature">Fermer</v-btn>
                                <v-btn class="ml-3 success" v-if="!!$refs.signature && !$refs.signature.isEmpty()" @click="submitWithSignature">Soumettre</v-btn>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-row class="mt-5">
                    <v-col cols="12" class="overflow-visible">
                        <!-- if we are outputting and we want to show each section in a tab -->
                        <v-tabs-items v-if="!!toggleTabs" v-model="tab" touchless class="overflow-visible">
                            <v-tab-item class="overflow-visible">
                                <SurveyInfo
                                    v-if="!!activeAssignation || !!activeSurvey"
                                    :activeAssignation="!!activeAssignation ? activeAssignation : ''"
                                    :activeSurvey="!!activeSurvey ? activeSurvey : ''"
                                    :stageUnits="stageUnits"
                                    :stageUnitId="stageUnitId"
                                    :selectedLang="selectedLang"
                                    @stageUnitIdChange="stageUnitIdChange"
                                    @startClick="cycleSections('right')"
                                    :read-only-unit="unitIsLoaded"
                                />
                            </v-tab-item>
                            <v-tab-item v-for="(section, sectionIndex) in sections" :key="section.id" class="overflow-visible">
                                <SurveySection
                                    :section="section"
                                    :sectionIndex="sectionIndex"
                                    :sectionsTotal="sections.length"
                                    :selectedLang="selectedLang"
                                    :assignationIdToOutput="assignationIdToOutput"
                                    :isSubmit="isSubmit"
                                    :getQuestionAnswer="getQuestionAnswer"
                                    :show-arrows="true"
                                    @answerChange="answerChanged"
                                    @imagesChange="imagesChange"
                                    @leftArrowClick="cycleSections('left')"
                                    @rightArrowClick="cycleSections('right')"
                                    :is-last-section="sectionIndex === sections.length - 1"
                                    @submit="submitSurvey"
                                    :skipped="getSectionSkip(section.id)"
                                    @skipSectionChange="toggleSectionSkip(section.id)"
                                    :readonly="readonly"
                                    :print="print"
                                    class="overflow-visible"
                                />
                            </v-tab-item>
                        </v-tabs-items>

                        <!-- if we are outputting and we want the sections to show without tabs -->
                        <v-card v-if="!toggleTabs" elevation="0">
                            <SurveyInfo
                                v-if="!!activeAssignation || !!activeSurvey"
                                :activeAssignation="!!activeAssignation ? activeAssignation : ''"
                                :activeSurvey="!!activeSurvey ? activeSurvey : ''"
                                :stageUnits="stageUnits"
                                :stageUnitId="stageUnitId"
                                :selectedLang="selectedLang"
                                @stageUnitIdChange="stageUnitIdChange"
                                :read-only-unit="unitIsLoaded"
                            />
                            <SurveySection
                                v-for="(section, sectionIndex) in sections"
                                :key="section.id"
                                :section="section"
                                :sectionIndex="sectionIndex"
                                :sectionsTotal="sections.length"
                                :selectedLang="selectedLang"
                                :assignationIdToOutput="assignationIdToOutput"
                                :assignationToPreview="assignationToPreview"
                                :isSubmit="isSubmit"
                                :getQuestionAnswer="getQuestionAnswer"
                                @answerChange="answerChanged"
                                @imagesChange="imagesChange"
                                :skipped="getSectionSkip(section.id)"
                                @skipSectionChange="toggleSectionSkip($event, section.id)"
                                :readonly="readonly"
                                :print="print"
                            />
                        </v-card>
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
    </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import SurveyInfo from './Sections/SurveyInfo';
import SurveySection from './Sections/SurveySection';
import shared from '../shared';
import _ from "lodash";

export default {
        name: "Survey",
        components: { SurveyInfo, SurveySection },
        props: ['assignationToPreview', 'surveyIdToPreview', 'assignationIdToOutput', 'readonly', 'print'],
        async mounted() {
            this.onSection = localStorage.getItem('onSurveySection');

            //if we are previewing a survey from IndexSurvey.vue
            if(!!this.surveyIdToPreview) {
                await this.fetchFullSurvey(this.surveyIdToPreview);
            }
            //if we are outputting the survey
            else if(!!this.assignationIdToOutput) {
                await this.fetchAssignationById(this.assignationIdToOutput);
            }
            else {
                this.hash = !!this.assignationToPreview ? this.assignationToPreview.hash : this.$route.params.hash;
                await this.fetchAssignation(this.hash);
            }

            this.selectedLang = !!this.activeAssignation ? this.activeAssignation.defaultLang : 'fr';
            this.answers = !!this.activeAssignation ? this.activeAssignation.answers : [];

            //if a stage unit has been assigned at the assignation's creation
            if(this.activeAssignation && this.activeAssignation.stageUnit) {
                this.stageUnitId = this.activeAssignation.stageUnit.id;
                this.unitIsLoaded = true;
            }

            this.setLanguage = shared.setLanguage;
            if (this.print) {
                this.toggleTabs = false;
            }

            if(this.onSection && !this.readonly){
                //this.addMessageToDevBuilder(this.onSection);
                this.tab = Number(this.onSection);
                this.toggleTabs = true;
            }
        },
        beforeDestroy() {
            //sets active assignation to null when exiting the output page
            this.setActiveAssignation(null);
        },
        computed: {
            ...mapState(['activeAssignation', 'activeSurvey', 'stageUnits', 'surveyType']),

            sections() {
                if (!!this.activeAssignation) {
                    return this.activeAssignation.surveyObject.sections;
                }
                else if(!!this.activeSurvey) {
                    return this.activeSurvey.sections;
                }

                return [];
            }
        },
        watch: {
            //confirmation for changing stage unit of survey
            stageUnitId(newVal, oldVal) {
                if(this.showConfirmation && !confirm("Êtes-vous sûre de vouloir changer l'unité de scène ? ")) {
                    /*nextTick allows you to do something after you have changed the data and VueJS has updated the DOM based on
                    your data change, but before the browser has rendered those changed on the page*/
                    this.$nextTick(() => this.stageUnitId = oldVal);
                    this.showConfirmation = false;
                }
            },
            tab(newVal, oldVal) {
                if (newVal !== oldVal && !this.readonly) {
                    this.saveAnswers();
                    localStorage.setItem('onSurveySection', newVal);
                }
            }
        },
        data() {
            return {
                hash: '',
                tab: 0,
                dirty: false,
                onLine: true,
                changedAnswersIndexes: [],
                //chosen language for survey
                selectedLang: '',
                //language drop down options
                langOptions: [
                    { text: 'Français', value: 'fr' },
                    { text: 'Anglais', value: 'en' },
                    { text: 'Espagnol', value: 'es' },
                ],
                dialogSelectLang: false,
                dialogImportFile: false,
                selectedFile: '',
                dialogSignature: false,
                answers: [],
                toggleTabs: true,

                //drawer menu
                drawer: false,
                //linking questions in survey with questions in drawer menu
                item: 0,
                //v-model of stage units v-select
                stageUnitId: null,
                //show confirmation if user interact with stage units v-select
                showConfirmation: false,
                //v-model v-speed-dial button
                speedDialBtn: false,
                //if submit button has been press
                isSubmit: false,

                //shared functions from shared.js
                setLanguage: function() {},

                //check if the unit was initially included in the assignation
                unitIsLoaded: false,

                onSection:null
            }
        },
        methods: {
            ...mapMutations(['setActiveAssignation']),
            ...mapActions(['fetchAssignation', 'fetchAssignationById', 'fetchFullSurvey','postAnswers', 'postSingleAnswer', 'addMessageToDevBuilder']),

            toggleSectionSkip(sectionId) {
                if (!!this.activeAssignation) {
                    if (!Array.isArray(this.activeAssignation.optionalSections)) {
                        this.activeAssignation.optionalSections = [];
                    }
                    let section = this.activeAssignation.surveyObject.sections.find(s => s.id === sectionId);

                    if (section && section.optional) {
                        //toggle the value
                        if (this.activeAssignation.optionalSections.includes(sectionId)) {
                            this.activeAssignation.optionalSections = this.activeAssignation.optionalSections.filter(s => s !== sectionId);
                        }
                        else {
                            this.activeAssignation.optionalSections.push(sectionId);
                        }
                        this.$forceUpdate();
                    }
                    this.dirty = true;
                }
            },

            getSectionSkip(sectionId) {
                if (!!this.activeAssignation && Array.isArray(this.activeAssignation.optionalSections)) {
                    return this.activeAssignation.optionalSections.includes(sectionId);
                }
                return false;
            },

            //section arrows
            cycleSections(direction) {
                if (direction === 'left') {
                    this.tab--;
                }
                else {
                    this.tab++;
                }
                window.scrollTo(0,0);
            },

            //drawer menu
            showDrawer() {
                this.drawer = !this.drawer;
            },

            //toggle between section in tabs or section on one long page
            toggle() {
                this.toggleTabs = !this.toggleTabs;
                this.showDrawer();
            },

            //go back to index assignation
            backToIndexAssignation() {
                this.$router.replace('/index-assignations');
            },

            //dialog signature
            closeDialogSignature() {
                this.$refs.signature.clear();
                this.dialogSignature = false
            },

            async saveJSON() {
                const link = document.createElement('a')
                link.href = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify({
                    assignationId: this.activeAssignation.id,
                    answers: this.answers
                }));
                link.download = "Assignation_" + this.activeAssignation.id + "_answers.json";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                this.onLine = navigator.onLine;
            },

            async loadJSON(file) {
                const jsonObject = JSON.parse(await file.text());
                if (jsonObject.assignationId === this.activeAssignation.id) {
                    this.selectedFile = _.cloneDeep(jsonObject);
                }
                else {
                    this.selectedFile = null;
                }
            },

            applyJSON() {
                if (this.selectedFile != null) {
                    for (let i = 0; i < this.answers.length; i++) {
                        this.answers[i] = _.cloneDeep(this.selectedFile.answers[i]);
                    }
                    alert("Success import");
                }
                else {
                    alert("No file selected");
                }
                this.dialogImportFile = false;
            },

            async saveAnswers(question = null) {
                if (!this.readonly && this.dirty) {
                    //if assignation state is Closed we cannot edit our save the survey anymore
                    if(!this.assignationIdToOutput && !this.isSubmit && this.activeAssignation.state === 'Closed') {
                        return alert(this.saveOrSubmitErrorMessage());
                    }

                    //if we are outputting the assignation and its state is not Closed we cannot modify the answers
                    if(!!this.assignationIdToOutput && this.activeAssignation.state !== 'Closed') {
                        return alert("Vous ne pouvez pas modifier une assignation qui n'est pas terminé");
                    }

                    var vm = this;
                    for (let i = 0; i < this.answers.length; i++) {
                        if (this.changedAnswersIndexes.includes(this.answers[i].questionId)) {
                            if (navigator.onLine) {
                                await this.postSingleAnswer({answer: this.answers[i], hash: !!this.assignationIdToOutput ? this.activeAssignation.hash : this.hash});
                                this.changedAnswersIndexes.splice(this.changedAnswersIndexes.indexOf(this.answers[i].questionId), 1);
                                if (!!this.answers[i].images) {
                                    this.answers[i].images.forEach((image) => {
                                        image.toUpload = false;
                                        if (this.surveyType.autosaveImages && !image.downloaded) {
                                            fetch(image.dataUrl).then(function(image) {
                                                image.blob().then(function(imageBlob) {
                                                    const imageURL = URL.createObjectURL(imageBlob)
                                                    const link = document.createElement('a')
                                                    link.href = imageURL
                                                    if (question != null) {
                                                        let titleObj = question.question.title.find(q => q.code === vm.selectedLang);
                                                        titleObj = titleObj == null ? question.question.title[0] : titleObj;
                                                        link.download = titleObj.text;
                                                    }
                                                    document.body.appendChild(link)
                                                    link.click()
                                                    document.body.removeChild(link)
                                                })
                                            })
                                            image.downloaded = true;
                                        }
                                    });
                                }
                            }
                            else if (!!this.answers[i].images) {
                                this.answers[i].images.forEach((image) => {
                                    fetch(image.dataUrl).then(function(image) {
                                        image.blob().then(function(imageBlob) {
                                            const imageURL = URL.createObjectURL(imageBlob)
                                            const link = document.createElement('a')
                                            link.href = imageURL
                                            if (question != null) {
                                                let titleObj = question.question.title.find(q => q.code === vm.selectedLang);
                                                titleObj = titleObj == null ? question.question.title[0] : titleObj;
                                                link.download = titleObj.text;
                                            }
                                            document.body.appendChild(link)
                                            link.click()
                                            document.body.removeChild(link)
                                        })
                                    })
                                });
                            }
                        }
                    }
                    this.dirty = false;
                    if (!navigator.onLine) {
                        this.onLine = false;
                        alert("You are working offline, you can use the orange button to save a file when to prevent loss of work (will contain all your answers)");
                    }
                    else {
                        this.onLine = true;
                    }
                }
            },

            //submit the survey
            async submitSurvey() {
                //if assignation state is Closed we cannot edit our save the survey anymore
                if(this.activeAssignation.state === 'Closed') {
                    return alert(this.saveOrSubmitErrorMessage());
                }

                this.isSubmit = true;

                //verify if all question are completed
                for(let section of this.sections) {
                    if(!this.getSectionCompleted(section)) {
                        return alert('Erreur(s) trouvé(s)');
                    }
                }

                if(confirm("Êtes-vous sûre de vouloir soumettre ce questionnaire ? Si oui, le questionnaire ne sera plus modifiable.")) {
                    //if a signature is needed, we open de signature dialog and we will save from ths dialog
                    if(!!this.surveyType.signatureNeeded) {
                        return this.dialogSignature = true;
                    }

                    //saving answers
                    await this.postAnswers({
                        hash: this.hash,
                        answers: this.answers,
                        optionalSections: this.activeAssignation.optionalSections,
                        stageUnitId: this.stageUnitId,
                        isSubmit: this.isSubmit
                    });

                    this.dirty = false;

                    //resets all images to uploaded so we wont upload them twice (toUpload = false)
                    //should be in the store (temporary solution)
                    this.answers.forEach((answer) => {
                        if (!!answer.images) {
                            answer.images.forEach((image) => {
                                image.toUpload = false;
                            });
                        }
                    });

                    //redirecting to info page (first page)
                    this.tab = 0;
                }
            },

            //error message if we try to submit or save an assignation with state Closed
            saveOrSubmitErrorMessage() {
                let message = "";

                if(this.selectedLang === 'en') {
                    message = "You cannot save or submit an assignation that is closed already.";
                }
                else {
                    message = "Impossible de sauvegarder ou soumettre une assignation qui est Terminé.";
                }

                return message;
            },

            //submit survey with needed signature
            async submitWithSignature() {
                const pngSignature = this.$refs.signature.save();

                await this.postAnswers({
                    hash: this.hash,
                    answers: this.answers,
                    stageUnitId: this.stageUnitId,
                    isSubmit: this.isSubmit,
                    optionalSections: this.activeAssignation.optionalSections,
                    signature: pngSignature });

                this.dirty = false;

                //should be in the store (temporary solution)
                this.answers.forEach((answer) => {
                    if (!!answer.images) {
                        answer.images.forEach((image) => {
                            image.toUpload = false;
                        });
                    }
                });

                //closing signature dialog
                this.dialogSignature = false;

                //redirecting to info page (first page)
                this.tab = 0;
            },

            //events du component QuestionAnswer
            answerChanged(event) {
                this.dirty = true;
                let answerIndex = this.answers.findIndex(answer => answer.questionId === event.question.id);

                if(!!this.answers[answerIndex]) {
                    this.answers[answerIndex].value = event.answer.value;
                    this.answers[answerIndex].optionsValue = event.answer.optionsValue;
                    this.answers[answerIndex].isComplete = event.answer.isComplete;
                    this.changedAnswersIndexes.push(this.answers[answerIndex].questionId);
                    this.$forceUpdate();
                }
            },
            async imagesChange(event) {
                let answerIndex = this.answers.findIndex(answer => answer.questionId === event.question.id);

                if(!!this.answers[answerIndex]) {
                    this.answers[answerIndex].images = event.images.filter(image => image.toUpload || image.toDelete);
                    await this.saveAnswers(event.question);
                    this.$forceUpdate();
                }
            },

            //stage unit id change event for SurveyInfo component
            stageUnitIdChange(stageUnitId) {
                this.showConfirmation = true;
                this.stageUnitId = stageUnitId;
            },

            //calcul du taux de completion d'une section
            getSectionCompletion(section) {
                if (this.getSectionSkip(section.id)) {
                    return section.questions.length;
                }
                let total = 0;
                section.questions.forEach((question) => {
                    let answerIndex = this.answers.findIndex(answer => answer.questionId === question.id);
                    if ((this.answers[answerIndex] && this.answers[answerIndex].isComplete) || !!question.question.optionOptional || (question.question.type === 'noanswer' && question.question.optionImages == null)) {
                        total++;
                    }
                    // if (question.question.type !== 'noanswer') {
                    //     if ((this.answers[answerIndex] && this.answers[answerIndex].isComplete) || !!question.question.optionOptional) {
                    //         total++;
                    //     }
                    // }
                    // else if (this.answers[answerIndex] && (this.answers[answerIndex].optionsValue.optionYesNo === 0 || this.answers[answerIndex].optionsValue.optionYesNo === 1)){
                    //     total++;
                    // }
                });
                return total;
            },
            getSectionCompleted(section) {
                let total = this.getSectionCompletion(section);
                return total === section.questions.length;
            },
            getQuestionAnswer(questionId) {
                let answer = this.answers.find((answer) => {
                    return answer.questionId === questionId;
                });
                return answer;
            },

            //print assignation
            printAssignation() {
                this.showDrawer();
                this.toggleTabs = false;
                //creates a time gap so we can set the screen properly for printing
                let timer = new Promise(function(res, rej) {
                    window.setTimeout(function () {
                        res();
                    }, 10);
                });
                timer.then(res => window.print());
            },
        }
    }
</script>

<style scoped>

</style>
<style>
    .anchor {
        text-decoration: none;
    }

    .signature {
        border: 2px solid #1976D2;
    }

    .overflow-hidden {
        overflow: hidden;
    }

    .scale {
        transform: scale(1.2);
    }

    /* print stylesheet */
    @media print {
        .print-none {
            display: none;
        }

        @page {
            margin-top: 20px;
        }

    }
</style>
