<template>
    <v-container class="pa-0" v-if="loggedUser.id == null">
        <Login @login="tryLogin"/>
    </v-container>
    <v-container style="width: 100%;" v-else>
        <v-card class="mt-10" v-if="loggedUser.shopUser !== 0">
            <v-card-title
                style="width: 100%; text-align: center; padding-top: 15px; padding-bottom: 15px; background-color: black; color:white;">
                <h1 style="width: 100%"><span class="gl-login-title" style="">Gallup</span></h1>
            </v-card-title>
            <v-card-text >
                <!-- For Desktop -->
                <v-container id="desktop-container">
                    <table style="margin: 0 auto;">
                        <tr>
                            <th class="pa-2 pa-md-3" style="border-bottom:1px solid black;border-right:1px solid black;font-size: 20px;">Questions</th>
                            <th class="pa-2 pa-md-3" style="border-bottom:1px solid black;border-right:1px solid black;font-size: 20px;">Questionnaires</th>
                            <th class="pa-2 pa-md-3" style="border-bottom:1px solid black;border-right:1px solid black;font-size: 20px;">Assignations</th>
                            <th class="pa-2 pa-md-3" style="border-bottom:1px solid black;border-right:1px solid black;font-size: 20px;">Maintenance</th>
                            <th class="pa-2 pa-md-3" style="border-bottom:1px solid black;border-right:1px solid black;font-size: 20px;">Administration</th>
                        </tr>
                        <tr>
                            <td class="pa-3"><v-btn class="responsive-btn" elevation="0" color="transparent" to="/index-question">Index des questions</v-btn></td>
                            <td class="pa-3"><v-btn class="responsive-btn" elevation="0" color="transparent" to="/index-survey">Index des questionnaires</v-btn></td>
                            <td class="pa-3"><v-btn class="responsive-btn" elevation="0" color="transparent" to="/index-assignations">Index des assignations</v-btn></td>
                            <td class="pa-3"><v-btn class="responsive-btn" elevation="0" color="transparent" to="/index-maintenance">Tableau de bord maintenance</v-btn></td>
                            <td class="pa-3"><v-btn class="responsive-btn" elevation="0" color="transparent" to="/user-management">Gestion des utilisateurs</v-btn></td>
                        </tr>
                        <tr>
                            <td class="pa-3"><v-btn class="responsive-btn" elevation="0" color="transparent" to="/index-categories" v-if="loggedUser.createTagsCategories">Categories de questions</v-btn></td>
                            <td class="pa-3"><v-btn class="responsive-btn" elevation="0" color="transparent" to="/index-survey-types" v-if="loggedUser.createSurveys">Types de questionnaires</v-btn></td>
                            <td class="pa-3"></td>
                            <td class="pa-3"><v-btn class="responsive-btn" elevation="0" color="transparent" to="/maintenance-stages">Scènes</v-btn></td>
                            <td class="pa-3"></td>
                        </tr>
                        <tr>
                            <td class="pa-3"><v-btn class="responsive-btn" elevation="0" color="transparent" to="/index-tags" v-if="loggedUser.createTagsCategories">Tags de questions</v-btn></td>
                            <td class="pa-3"></td>
                            <td class="pa-3"></td>
                            <td class="pa-3"><v-btn class="responsive-btn" elevation="0" color="transparent" to="/index-nc-reports">Index des rapports</v-btn></td>
                            <td class="pa-3"></td>
                        </tr>
                    </table>
                </v-container>
                <!-- For Mobile -->
                <v-container id="mobile-container">
                    <v-row>
                        <v-col>
                            <div class="gl-fs-24 pa-3" style="border-bottom:1px solid black;border-right:1px solid black;">Questions</div>
                            <div class="pa-3" ><v-btn  elevation="0" color="transparent" to="/index-question">Index des questions</v-btn></div>
                            <div class="pa-3" ><v-btn  elevation="0" color="transparent" to="/index-categories" v-if="loggedUser.createTagsCategories">Categories de questions</v-btn></div>
                            <div class="pa-3" ><v-btn  elevation="0" color="transparent" to="/index-tags" v-if="loggedUser.createTagsCategories">Tags de questions</v-btn></div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div class="gl-fs-24 pa-3" style="border-bottom:1px solid black;border-right:1px solid black;">Questionnaires</div>
                            <div class="pa-3" ><v-btn  elevation="0" color="transparent" to="/index-survey">Index des questionnaires</v-btn></div>
                            <div class="pa-3" ><v-btn  elevation="0" color="transparent" to="/index-survey-types" v-if="loggedUser.createSurveys">Types de questionnaires</v-btn></div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div class="gl-fs-24 pa-3" style="border-bottom:1px solid black;border-right:1px solid black;">Assignations</div>
                            <div class="pa-3" ><v-btn  elevation="0" color="transparent" to="/index-assignations">Index des assignations</v-btn></div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div class="gl-fs-24 pa-3" style="border-bottom:1px solid black;border-right:1px solid black;">Maintenance</div>
                            <div class="pa-3" ><v-btn  elevation="0" color="transparent" to="/index-maintenance">Tableau de bord maintenance</v-btn></div>
                            <div class="pa-3" ><v-btn  elevation="0" color="transparent" to="/maintenance-stages">Scènes</v-btn></div>
                            <div class="pa-3" ><v-btn  elevation="0" color="transparent" to="/index-nc-reports">Index des rapports</v-btn></div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <div class="gl-fs-24 pa-3" style="border-bottom:1px solid black;border-right:1px solid black;">Administration</div>
                            <div class="pa-3" ><v-btn  elevation="0" color="transparent" to="/user-management">Gestion des utilisateurs</v-btn></div>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-container>
</template>

<script>
import Login from "../../../../shared/vue_components/Login";
import { mapActions, mapState } from 'vuex'
import IndexAssignations from "@/views/IndexAssignations.vue";

export default {
    components: { IndexAssignations, Login },
    async mounted() {

    },
    data() {
        return {
            email: '',
            password: ''
        }
    },
    computed: {
        ...mapState(['loggedUser'])
    },
    methods: {
        ...mapActions([
            'login'
        ]),
        async tryLogin(loginInfo) {
            await this.login(loginInfo);
            if (this.loggedUser.id != null) {
                if(this.loggedUser.shopUser === 0){
                    await this.$router.push('/index-nc-reports');
                }
                else{
                    await this.$router.push('/');
                }
            }
        }
    }
};
</script>

<style scoped>
.responsive-btn {
    width: 100%;
    height: auto !important;
    white-space: normal !important;
    word-wrap: break-word !important;
    hyphens: auto !important;
    text-transform: none !important;
    line-height: 1.2 !important;
    padding: 8px !important;
}

#desktop-container {
    display: block;
    overflow-x: auto;
}

#mobile-container {
    display: none;
}

table {
    table-layout: fixed;
}

td, th {
    word-wrap: break-word;
    overflow-wrap: break-word;
}

@media (max-width: 1270px) {
    #desktop-container {
        display: none;
    }

    #mobile-container {
        display: block;
    }
}
</style>
