<template>
    <v-container style="width: 100%; max-width: fit-content;">
        <v-card elevation="0" style="background-color: #f5f5f5">
            <v-card-title>
                <h1>Index des assignations</h1>

                <v-divider
                    class="mx-4"
                    inset
                    vertical
                />

                <!-- button dialog edit question -->
                <v-btn
                    color="primary"
                    dark
                    :fab="!!mobileDisplay"
                    :bottom="!!mobileDisplay"
                    :right="!!mobileDisplay"
                    :fixed="!!mobileDisplay"
                    :class="!!mobileDisplay ? 'button-shadow' : ''"
                    @click="dialogSurveyPicker = true"
                >
                    <v-icon>how_to_reg</v-icon>
                    {{ !mobileDisplay ? ' Nouvelle Assignation' : '' }}
                </v-btn>

                <v-spacer/>

                <!-- refresh button -->
                <v-btn
                    class="primary--text"
                    elevation="0"
                    title="Actualiser"
                    @click="refreshAssignations"
                >
                    <v-icon>sync</v-icon>
                    {{ !mobileDisplay ? 'Actualiser' : '' }}
                </v-btn>
            </v-card-title>

            <!-- ASSIGNATIONS DATA TABLE -->
            <v-data-table
                :headers="!mobileDisplay ? assignationsHeaders : mobileAssignationsHeaders"
                :items="assignations"
                :custom-filter="filter"
                style="background-color: #f5f5f5"
            >
                <!-- headers -->
                <template v-slot:header.id="{ header }">
                    <input v-model="header.search" type="text" placeholder="Id" class="search-input-header" @input="filter" @click="stopEvent($event)"/>
                </template>
                <template v-slot:header.surveyTitle="{ header }">
                    <input v-model="header.search" type="text" placeholder="Titre survey" class="search-input-header" @input="filter" @click="stopEvent($event)"/>
                </template>
                <template v-slot:header.userFullName="{ header }">
                    <input v-model="header.search" type="text" placeholder="Assignateur" class="search-input-header" @input="filter" @click="stopEvent($event)"/>
                </template>
                <template v-slot:header.assignedFullName="{ header }">
                    <input v-model="header.search" type="text" placeholder="Assigné" class="search-input-header" @input="filter" @click="stopEvent($event)"/>
                </template>
                <template v-slot:header.state="{ header }">
                    <input v-model="header.search" type="text" placeholder="État" class="search-input-header" @input="filter" @click="stopEvent($event)"/>
                </template>
                <template v-slot:header.stageModel="{ header }">
                    <input v-model="header.search" type="text" placeholder="Modèle scène" class="search-input-header" @input="filter" @click="stopEvent($event)"/>
                </template>
                <template v-slot:header.stageUnit="{ header }">
                    <input v-model="header.search" type="text" placeholder="Unité scène" class="search-input-header" @input="filter" @click="stopEvent($event)"/>
                </template>
                <template v-slot:header.createdAt="{ header }">
                    <input v-model="header.search" type="text" placeholder="Création" class="search-input-header" @input="filter" @click="stopEvent($event)"/>
                </template>
                <template v-slot:header.updatedAt="{ header }">
                    <input v-model="header.search" type="text" placeholder="Mise à jour" class="search-input-header" @input="filter" @click="stopEvent($event)"/>
                </template>
                <template v-slot:header.action="{ header }">
                    <span style="font-size: 18px;">Actions</span>
                </template>

                <!-- items -->
                <template v-slot:item.id="{ item }">
                    <span class="font-weight-bold clickable gl-fs-16" @click="showAssignationInfos(item.id)">{{ item.id }}</span>
                </template>
                <template v-slot:item.surveyTitle="{ item }">
                    <p class="caption pt-4"><span class="gl-fs-16">{{ item.surveyTitle }}</span></p>
                </template>
                <template v-slot:item.userFullName="{ item }">
                    <p class="caption pt-4"><span class="gl-fs-16">{{ item.userFullName }}</span></p>
                </template>
                <template v-slot:item.assignedFullName="{ item }">
                    <p class="caption pt-4"><span class="gl-fs-16">{{ item.assignedFullName || 'N/A' }}</span></p>
                </template>
                <template v-slot:item.state="{ item }">
                    <v-chip :color="getStateColor(item.state)" dark @click="showStateHistory(item.id)"><span class="gl-fs-16">{{ getStatesWithFrenchLabel(item.state) }}</span></v-chip>
                </template>
                <template v-slot:item.stageModel="{ item }">
                    <p class="caption pt-4"><span class="gl-fs-16">{{ item.stageModel }}</span></p>
                </template>
                <template v-slot:item.stageUnit="{ item }">
                    <p class="caption pt-4"><span class="gl-fs-16">{{ item.stageUnit }}</span></p>
                </template>
                <template v-slot:item.createdAt="{ item }">
                    <p class="caption pt-4"><span class="gl-fs-16">{{ item.createdAt }}</span></p>
                </template>
                <template v-slot:item.updatedAt="{ item }">
                    <p class="caption pt-4"><span class="gl-fs-16">{{ item.updatedAt }}</span></p>
                </template>
                <template v-slot:item.action="{ item }">
                    <v-row style="width: 300px">
                        <v-icon
                            v-if="!mobileDisplay && !!loggedUser && !!loggedUser.createAssignations"
                            class="mr-2"
                            @click="sendReminder(item)"
                            title="Envoyer"
                        >send</v-icon>
                        <v-icon
                            class="mr-2"
                            @click="goToSurvey(item)"
                            title="Lien questionnaire"
                        >web</v-icon>
                        <v-icon
                            class="mr-2"
                            @click="goToInspectionReport(item)"
                            title="Lien Rapport"
                        >assignment_turned_in</v-icon>
                        <v-icon
                            class="mr-2"
                            @click="goToSAVInspectionReport(item)"
                            title="Lien Rapport SAV"
                        >mdi-certificate</v-icon>
                        <v-icon
                            class="mr-2"
                            v-if="!mobileDisplay && !!loggedUser && !!loggedUser.createAssignations"
                            @click="confirmDeleteAssignation(item)"
                            title="Supprimer"
                        >delete</v-icon>
                        <v-icon
                            class="mr-2"
                            title="Date d'expiration du rapport"
                            @click="openAssignationExpirationDateDialog(item)"
                        >event</v-icon>
                        <v-icon
                            class="mr-2"
                            title="Impression du rapport"
                            @click="printAssignation(item)"
                        >print</v-icon>
                        <v-btn
                            @click="toggleAssignationLang(item)"
                            icon
                        ><span class="gl-fs-16">{{item.defaultLang}}</span></v-btn>
                    </v-row>
                </template>

                <!-- mobile view -->
                <template v-if="!!mobileDisplay" v-slot:item="{ item, index }">
                    <v-expansion-panels>
                        <v-expansion-panel :class="index % 2 !== 0 ? 'grey lighten-2' : ''">
                            <v-expansion-panel-header>
                                <div class="caption">
                                    <p><strong>Titre questionnaire: </strong>{{ item.surveyTitle }}</p>
                                    <p>
                                        <strong>Actions: </strong>
                                        <v-icon v-if="item.type === 'autoAssigned'" small class="mr-2" @click="goToSurvey(item)" title="Lien questionnaire">web</v-icon>
                                    </p>
                                </div>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                                <div class="caption">
                                    <p>
                                        <strong>Assignation Id: </strong>
                                        {{ item.id }}
                                    </p>

                                    <p>
                                        <strong>État: </strong>
                                        <v-chip :color="getStateColor(item.state)" dark>{{ getStatesWithFrenchLabel(item.state) }}</v-chip>
                                    </p>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </template>
            </v-data-table>
        </v-card>

        <!-- assignation infos dialog -->
        <v-dialog v-model="dialogAssignationInfos">
            <v-card>
                <v-card-title>Infos Supplémentaires Assignations</v-card-title>

                <v-data-table
                    :headers="assignationHeaders"
                    :items="[activeAssignation]"
                    hide-default-footer
                >
                    <template v-slot:item.type="{ item }">
                        {{ getAssignationTypeTraduction(item.type) }}
                    </template>
                    getFullLanguageText
                    <template v-slot:item.defaultLang="{ item }">
                        {{ getFullLanguageText(item.defaultLang) }}
                    </template>
                    <template v-slot:item.projectNumber="{ item }">
                        {{ item.projectNumber || 'N/A' }}
                    </template>
                    <template v-slot:item.clientSupplier.name="{ item }">
                        {{ !!item.clientSupplier ? item.clientSupplier.name : 'N/A' }}
                    </template>
                    <template v-slot:item.assignedUser.fullName="{ item }">
                        {{ !!item.assignedUser ? item.assignedUser.fullName : 'N/A' }}
                    </template>
                    <!-- lots of user dont have a division in the datatbase so we check for that as well -->
                    <template v-slot:item.assignedUser.division="{ item }">
                        {{ !!item.assignedUser && !!item.assignedUser.division ? item.assignedUser.division : 'N/A' }}
                    </template>
                    <template v-slot:item.assignedContact.fullName="{ item }">
                        {{ !!item.assignedContact ? item.assignedContact.fullName : 'N/A' }}
                    </template>
                </v-data-table>
                <v-card-actions>
                    <v-spacer />

                    <v-btn @click="dialogAssignationInfos = false">Fermer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- states history dialog -->
        <v-dialog v-model="dialogStatesHistory">
            <v-card>
                <v-card-title>
                    Historique Des États
                </v-card-title>

                <v-data-table
                    :headers="statesHistoryHeaders"
                    :items="!!activeAssignation ? activeAssignation.surveyAssignationState : []"
                >
                    <template v-slot:item.transition="{ item }">
                        <v-chip :color="getStateColor(item.transition)" dark>{{ getStatesWithFrenchLabel(item.transition) }}</v-chip>
                    </template>
                    <template v-slot:item.to="{ item }">
                        <v-chip :color="getStateColor(item.to)" dark>{{ getStatesWithFrenchLabel(item.to) }}</v-chip>
                    </template>
                </v-data-table>

                <v-card-actions>
                    <v-spacer />

                    <v-btn @click="dialogStatesHistory = false">
                        Fermer
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- dialog preview survey with answers-->
        <v-dialog v-model="dialogPreviewSurveyAnswers" width="50%">
            <v-card>
                <v-card-title>Aperçu Survey</v-card-title>

                <Survey
                    v-if="!!dialogPreviewSurveyAnswers"
                    :assignationToPreview="assignationToPreview"
                    :readonly="true"
                />

                <v-card-actions>
                    <v-spacer />

                    <v-btn @click="closePreviewSurveyAnswersDialog">Fermer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- dialog survey picker (for auto assignation) -->
        <v-dialog v-model="dialogSurveyPicker" width="100%">
            <v-card>
                <v-card-title>Choisir questionnaire</v-card-title>

                <v-card-text>
                    <v-data-table
                        :headers="!mobileDisplay ? surveyPickerHeaders : mobileSurveyPickerHeaders"
                        :items="surveys"
                    >
                        <!-- items -->
                        <template v-slot:item.actions="{ item }">
                            <v-icon @click="assign(item, false)" title="Assignation">person</v-icon>
                            <v-icon @click="assign(item, true)" title="Auto Assignation">how_to_reg</v-icon>
                        </template>

                        <!-- mobile view -->
                        <template v-if="!!mobileDisplay" v-slot:item="{ item, index }">
                            <div :class="index % 2 !== 0 ? 'pa-1 grey lighten-2' : 'pa-1'">
                                <p class="caption"><strong>Titre: </strong>{{ item.title[0].text }}</p>
                                <p class="caption"><strong>Action: </strong><v-icon @click="assign(item, false)" title="Assignation">person</v-icon></p>
                                <p class="caption"><strong>Action: </strong><v-icon @click="assign(item, true)" title="Auto Assignation">how_to_reg</v-icon></p>
                            </div>
                        </template>
                    </v-data-table>
                </v-card-text>

                <v-card-actions class="buttons-position">
                    <v-spacer/>

                    <v-btn class="button-shadow mr-3" @click="dialogSurveyPicker = false">Fermer</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- dialog new assignation -->
        <NewAssignationDialog
            :dialogNewAssignation="dialogNewAssignation"
            :isAutoAssigned="isAutoAssignation"
            :activeSurvey="activeSurvey"
            @closeDialogNewAssignation="closeDialogNewAssignation"
        />

        <!-- dialog expiration date picker -->
        <v-dialog
            v-model="dialogExpirationDate"
            width="350px"
        >
            <v-date-picker
                v-if="!!activeAssignation"
                v-model="activeAssignation.expirationDate"
                scrollable
            >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="dialogExpirationDate = false">Annuler</v-btn>
                <v-btn text color="primary" @click="resetAssignationExpirationDate">Supprimer Date</v-btn>
                <v-btn text color="primary" @click="closeAssignationExpirationDateDialog">OK</v-btn>
            </v-date-picker>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import NewAssignationDialog from "../components/Dialogs/NewAssignationDialog";
import Survey from '../components/Survey';
import shared from '../shared';

export default {
    name: 'IndexAssignations',
    components: { Survey, NewAssignationDialog },
    async mounted() {
        await this.fetchUser();

        if (!!this.loggedUser) {
            await this.refreshAssignations();

            await  this.fetchSurveys();

            this.getStateColor = shared.getStateColor;
            this.getStatesWithFrenchLabel = shared.getStatesWithFrenchLabel;
            this.getAssignationTypeTraduction = shared.getAssignationTypeTraduction;
            this.getFullLanguageText = shared.getFullLanguageText;
            this.filterTableData = shared.filterTableData;

            this.originalAssignations = this.assignations;
        }
    },
    beforeDestroy() {
        //reset the active survey to null every time we exit the page
        this.setActiveSurvey(null);
        this.setActiveAssignation(null);
    },
    data: () => {
        return {
            isAutoAssignation: false,
            //dialogs
            dialogAssignationInfos: false,
            dialogStatesHistory: false,
            dialogPreviewSurveyAnswers: false,
            dialogSurveyPicker: false,
            dialogEditSurvey: false,
            //dialogNewAssignation is passed as a prop in the component NewAssignationDialog
            dialogNewAssignation: false,
            dialogExpirationDate: false,

            //copy of original assignations list
            originalAssignations: [],

            //assignation to preview
            assignationToPreview: null,

            //shared function from shared.js
            getStateColor: function() {},
            getStatesWithFrenchLabel: function() {},
            getAssignationTypeTraduction: function() {},
            getFullLanguageText: function() {},
            filterTableData: function() {},

            // assignations headers
            assignationsHeaders: [
                { text: 'Id', value: 'id', search: '' },
                { text: 'Nom Survey', value: 'surveyTitle', search: '' },
                { text: 'Nom Assignateur', value: 'userFullName', search: '' },
                { text: 'Nom Assigné', value: 'assignedFullName', search: '' },
                { text: 'État', value: 'state', search: '' },
                { text: 'Modèle scène', value: 'stageModel', search: '' },
                { text: 'Unité scène', value: 'stageUnit', search: '' },
                { text: 'Création', value: 'createdAt', search: '' },
                { text: 'Mise À Jour', value: 'updatedAt', search: '' },
                { text: 'Actions', value: 'action' }
            ],
            //mobile view assignations headers
            mobileAssignationsHeaders: [
                { text: 'Nom Survey', value: 'surveyTitle', search: '' },
                { text: 'Actions', value: 'action' }
            ],
            //specific assignation headers
            assignationHeaders: [
                { text: 'Type', value: 'type' },
                { text: 'Langue par défaut', value: 'defaultLang' },
                { text: 'Numéro de projet', value: 'projectNumber' },
                { text: 'Nom client', value: 'clientSupplier.name' },
                { text: 'Nom Assignateur', value: 'user.fullName' },
                { text: 'Nom Utilisateur Assigné', value: 'assignedUser.fullName' },
                { text: 'Division Utilisateur Assigné', value: 'assignedUser.division' },
                { text: 'Email Assigné', value: 'email' },
                { text: 'Nom Contact Assigné', value: 'assignedContact.fullName' },
            ],
            //states history headers
            statesHistoryHeaders: [
                { text: 'Id Transiton', value: 'id' },
                { text: 'Id Assignation', value: 'assignationId' },
                { text: 'État Début', value: 'transition' },
                { text: 'État Fin', value: 'to' },
                { text: 'Création', value: 'createdAt' },
            ],
            //survey picker headers
            surveyPickerHeaders: [
                { text: 'Titre', value: 'title[0].text' },
                { text: 'Type', value: 'surveyType.name' },
                { text: 'Actions', value: 'actions' },
            ],
            //mobile survey picker headers
            mobileSurveyPickerHeaders: [
                { text: 'Titre', value: 'title[0].text' },
                { text: 'Actions', value: 'actions' },
            ],
        }
    },
    computed: {
        ...mapState(['surveys', 'activeSurvey', 'assignations', 'activeAssignation', 'loggedUser']),

        mobileDisplay() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return true;
                case 'sm': return true;
                case 'md': return false;
                case 'lg': return false;
                case 'xl': return false;
            }
        },
    },
    methods: {
        ...mapMutations(['setActiveSurvey', 'setAllAssignations', 'setActiveAssignation']),
        ...mapActions(['fetchUser', 'fetchSurveys', 'fetchAllAssignations', 'fetchAssignationById', 'fetchAssignationsByUserId', 'deleteAssignation', 'sendAssignationReminder', 'saveAssignationExpirationDate', 'setAssignationLang', 'isUserConnect']),

        //refresh page
        async refreshAssignations() {
            //if the current user only has rights for auto assignations, we fetch only the assignations related to him
            if(!!this.loggedUser.createAssignations || !!this.loggedUser.admin) {
                await this.fetchAllAssignations();
            }
            //else, we fetch all assignations
            else {
                await this.fetchAssignationsByUserId(this.loggedUser.id);
            }
        },

        printAssignation(assignation) {
            this.$router.push({ name: 'print-assignation', params: {hash: assignation.hash}});
        },

        async toggleAssignationLang(assignation) {
            const ogLang = assignation.defaultLang;
            assignation.defaultLang = ogLang === 'en' ? 'fr' : 'en';
            const success = await this.setAssignationLang(assignation);
            console.log(success);
            if (success !== 1) {
                assignation.defaultLang = ogLang;
            }
        },

        //BUTTON IS HIDDEN FOR NOW
        //preview survey
        /*previewSurveyAnswers(assignation) {
            //we pass the assignation for preview because Survey.vue(component used for the preview) will use the hash to fetch the assignation
            this.assignationToPreview = assignation;
            this.dialogPreviewSurveyAnswers = true;
        },*/
        closePreviewSurveyAnswersDialog() {
            this.dialogPreviewSurveyAnswers = false;
            //set the current assignation to null so it wont cause any bugs while previewing a survey or a question
            this.setActiveAssignation(null);
        },

        //delete assignation confirmation
        confirmDeleteAssignation(assignation) {
            confirm("Êtes-vous sûre de vouloir supprimer cet assignation ?") && this.deleteAssignation(assignation);
        },

        //send reminders
        async sendReminder(assignation) {
            const sent = await this.sendAssignationReminder(assignation);
            if(sent) {
                //creates a time gap so we can set the screen properly for reminder confirmation message
                let timer = new Promise(function(res, rej) {
                    window.setTimeout(function () {
                        res();
                    }, 100);
                });
                timer.then(res =>  alert("Le rappel a été envoyé avec succès!"));
            }
        },

        //BUTTON IS HIDDEN FOR NOW
        //edit assignation output
        /*editOutput(assignation) {
            this.$router.push(`/edit-assignation/${assignation.id}`);
        },*/

        //go to survey url
        goToSurvey(assignation) {
            window.open(`/survey/${assignation.hash}`, '_blank');
        },

        //go to survey url
        goToInspectionReport(assignation) {
            window.open(`/inspection-report/${assignation.clientHash}`, '_blank');
        },

        //go to survey url
        goToSAVInspectionReport(assignation) {
            window.open(`/sav-inspection-report/${assignation.clientHash}`, '_blank');
        },

        //show dialog states history
        async showStateHistory(assignationId) {
            await this.fetchAssignationById(assignationId);
            this.dialogStatesHistory = true;
        },

        //shows dialog assignation infos
        async showAssignationInfos(assignationId) {
            await this.fetchAssignationById(assignationId);
            this.dialogAssignationInfos = true;
        },

        //auto assignation
        assign(survey, isAutoAssignation = true) {
            this.setActiveSurvey(survey.id);
            this.isAutoAssignation = isAutoAssignation;
            this.dialogNewAssignation = true;
        },

        //filter of surveys search box
        filter() {
            const filteredData = this.filterTableData(this.originalAssignations, this.assignationsHeaders, 'assignations');
            this.setAllAssignations(filteredData);
        },

        //NEW ASSIGNATION
        closeDialogNewAssignation() {
            this.refreshAssignations();
            this.dialogNewAssignation = false;
        },

        //assignation expiration date dialog
        openAssignationExpirationDateDialog(item) {
            this.dialogExpirationDate = true;
            this.setActiveAssignation(item);
        },
        async closeAssignationExpirationDateDialog() {
            await this.saveAssignationExpirationDate(this.activeAssignation);
            this.dialogExpirationDate = false;
        },
        resetAssignationExpirationDate() {
            this.activeAssignation.expirationDate = null;
            this.closeAssignationExpirationDateDialog();
        },
        stopEvent($event) {
            $event.preventDefault();
            $event.stopPropagation();
        },
    }
}
</script>

<style scoped>
.caption {
    font-size: 18px;
}

.buttons-position {
    position: sticky;
    z-index: 100;
    bottom: 2%;
    left: 93%;
    width: 90px;
}

.button-shadow {
    box-shadow: 0 0 15px #000;
}

.search-input-header {
    width: 70%;
    border-bottom: 1px solid #000;
    padding-bottom: 1px;
    outline: none;
    font-size: 18px;
}
</style>
